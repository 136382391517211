import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import { FaShareSquare } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import { FaFacebookF } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaRedditAlien } from 'react-icons/fa';
import SEO from "../components/seo";


class Article extends React.Component {
  render () {
    const html = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const image =  html.frontmatter.image
      ? html.frontmatter.image.childImageSharp.resize
      : null
    return(
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title = {html.frontmatter.title}
          description = {html.frontmatter.description || html.excerpt}
          image = {image}
          pathname = {this.props.location.pathname}
        />
        <div className="mx-auto article">

            <section>
              <div className="w-full">
                <span className="float-left items-baseline mt-4">
                  <span className="flex justify-end">
                    <button id="social-btn" onClick={socialToggle} className="transition duration-300 ease-in-out bg-dlblue text-white px-2 py-2 rounded focus:outline-none hover:bg-dlblue-dark">
                      <span className="flex items-baseline"><FaShareSquare /><div className="pl-1">Share</div></span> 
                    </button>
                    <div id="social" className="flex items-center py-1 hidden">
                      <a target="_blank" rel="noreferrer noopener" href={"https://facebook.com/sharer/sharer.php?u=https://blog.friendliest.app" + this.props.location.pathname} className="hover-trigger transition duration-300 ease-in-out px-2.5 py-2 mr-2 text-white bg-dlblue rounded-full hover:text-white hover:bg-dlblue-dark" onClick={socialToggle}>
                        <FaFacebookF /><span id="share_facebook" className="hover-target z-50 hidden absolute rounded-lg p-3 bg-dlblue bg-opacity-90 text-white transition-opacity duration-1000 ease-out">Share on Facebook</span>
                      </a>
                      <a target="_blank" rel="noreferrer noopener" href={"https://twitter.com/intent/tweet/?text=" + html.frontmatter.title + "&url=https://blog.friendliest.app" + this.props.location.pathname} className="hover-trigger transition duration-300 ease-in-out px-2.5 py-2 mr-2 text-white bg-dlblue rounded-full hover:text-white hover:bg-dlblue-dark" onClick={socialToggle}>
                        <FaTwitter /><span id="share_twitter" className="hover-target z-50 hidden absolute rounded-lg p-3 bg-dlblue bg-opacity-90 text-white transition-opacity duration-1000 ease-out">Share on Twitter</span>
                      </a>
                      <a target="_blank" rel="noreferrer noopener" href={"https://reddit.com/submit/?url=https://blog.friendliest.app" + this.props.location.pathname + "&resubmit=true&title=" + html.frontmatter.title} className="hover-trigger transition duration-300 ease-in-out px-2.5 py-2 mr-2 text-white bg-dlblue rounded-full hover:text-white hover:bg-dlblue-dark" onClick={socialToggle}>
                        <FaRedditAlien /><span id="share_reddit" className="hover-target z-50 hidden absolute rounded-lg p-3 bg-dlblue bg-opacity-90 text-white transition-opacity duration-1000 ease-out">Share on Reddit</span>
                      </a>
                      <button id="social-close" onClick={socialToggle} className="hover-trigger transition duration-300 ease-in-out px-2.5 py-2 text-white bg-dlblue rounded-full focus:outline-none hover:text-white hover:bg-dlblue-dark">
                        <FaTimes /><span id="close-social" className="hover-target z-50 hidden absolute rounded-lg p-3 bg-dlblue bg-opacity-90 text-white transition-opacity duration-1000 ease-out">Close</span>
                      </button>
                    </div>
                  </span>
                </span>
              </div>
              <div className="flex w-full justify-between items-top">
                <h2 className="font-header md:text-3xl text-xl py-4 capitalize">{html.frontmatter.title}</h2>
              </div>
              <div dangerouslySetInnerHTML={{ __html: html.html }}></div>
                
              <p className="pt-8">{html.frontmatter.author} | {html.frontmatter.date}</p>
            </section>
        </div>
        </Layout>
    )
  }
}

function socialToggle () {
  const social = document.getElementById("social");
  const button = document.getElementById("social-btn");
  social.classList.toggle('hidden');
  button.classList.toggle('hidden');
}

export default Article

export const query = graphql`
  query ArticleQuery($slug: String) {
    site {
      siteMetadata {
        title
        siteUrl
        siteAuthor
      }
    }
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        tag
        author
        date (formatString: "dddd, MMMM Do YYYY")
        image {
            childImageSharp {
              resize(width: 1200) {
                src
                height
                width
              }
            }
        }
      }
    }
  }
`